import React from 'react';

function AcceptOurRules() {
    const privacyPolicy = process.env.REACT_APP_PRIVACY_POLICY;
    const termsAndConditions = process.env.REACT_APP_TERMS_AND_CONDITIONS;

    return (
        <div className="footer-text center">
            Using Ordopus means you have accepted <a className="text-link" href={privacyPolicy}> privacy policy </a> and <a className="text-link" href={termsAndConditions}> terms and conditions</a>.
        </div>
    );
}

export default AcceptOurRules;