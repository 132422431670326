import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import "./Dashboard.css"

function Dashboard() {
  const { setHeaderTitle } = useContext(HeaderContext);

  useEffect(() => {
      setHeaderTitle('Your management, your success!');
  }, [setHeaderTitle]);

  return (
    <div className="main-content">
      <TodayOrders />
      <QuickNotes />
      <Productivity />
      <ImprovementDiscipline />
      <Categories />
      <Projects />
    </div>
  );
}

function TodayOrders() {
  return (
    <div className="blocked today-functionality">
      <h3>Today Ordpus</h3>
      <div className="progress-ring">
        <div className="semi-circle-plot semi-circle-plot-text"><span>Progressed: 5/8</span></div>
        <div className="flex-container spacing today-process-block">
          <span>Completed: 62%</span>
          <span>In Progress: 38%</span>
        </div>
      </div>
    </div>
  );
}

function QuickNotes() {
  return (
    <div className="quick-notes">
      <button type="button" className="secondary-color-btn full-button elevated-btn">Schedule your Quick Notes</button>
    </div>
  );
}

function Productivity() {
  return (
    <div className="blocked productivity">
      <h3>Productivity</h3>
      <div className="time-range">Last 6 months</div>
      <div className="chart">
        { 'Add your chart implementation here' }
      </div>
    </div>
  );
}

function ImprovementDiscipline() {
  return (
    <div className="blocked improvement-discipline">
      <div className="improvement">
        <h4>Improvement</h4>
        <p>16%</p>
      </div>
      <div className="discipline">
        <h4>Discipline</h4>
        <p>34%</p>
      </div>
    </div>
  );
}

function Categories() {
  return (
    <div className="blocked categories">
      <h3>Categories</h3>
      <div className="pie-chart">
         {'Add your pie chart implementation here'}
      </div>
    </div>
  );
}

function Projects() {
  return (
    <div className="blocked projects">
      <h3>How everything goes in your projects</h3>
      <div className="project-list">
        <div className="project">Project 1</div>
        <div className="project">Project 2</div>
        <div className="project">Project 3</div>
        <div className="project">Project 4</div>
      </div>
    </div>
  );
}

export default Dashboard;