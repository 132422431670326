import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './FooterNavBar.css';

function FooterNavBar() {
    const location = useLocation();
    const [hovered, setHovered] = useState(null);

    const menuItems = [
        { to: '/', icon: 'home', label: 'Home' },
        { to: '/tasks/', icon: 'tasks-list', label: 'All Tasks' },
        { to: '/tasks/new/', icon: 'add', label: 'Add' },
        { to: '/calendar/', icon: 'calendar', label: 'Calendar' },
        { to: '/dashboard/', icon: 'dashboard', label: 'Dashboard' }
    ];

    const getIconSrc = (name, isActive) => {
        const baseSrc = `/images/icons/gray-${name}.png`;
        const activeSrc = `/images/icons/colored-${name}.png`;
        return isActive ? activeSrc : baseSrc;
    };

    return (
        <div className="navbar">
            {menuItems.map(item => {
                const isActive = location.pathname === item.to;
                const isHovered = hovered === item.to;

                return (
                    <Link 
                        key={item.to} 
                        className={`nav-item no-decoration ${isActive ? 'active' : 'deactive'}`} 
                        to={item.to}
                        onMouseEnter={() => setHovered(item.to)}
                        onMouseLeave={() => setHovered(null)}
                    >
                        <img 
                            className={`icon ${isActive ? 'active' : 'deactive'}`} 
                            alt={`${item.label} icon`} 
                            src={getIconSrc(item.icon, isActive || isHovered)} 
                        />
                        <label className={`no-decoration ${isActive ? 'active' : isHovered ? 'active' : 'deactive'}`}>
                            {item.label}
                        </label>
                    </Link>
                );
            })}
        </div>
    );
}

export default FooterNavBar;
