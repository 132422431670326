import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import axios from './axiosConfig';
import "./TaskForm.css";

function TaskForm() {

    const { taskId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const returnUrl = location.state?.returnUrl || '/';
    const { setHeaderTitle } = useContext(HeaderContext);
    const [title, setTitle] = useState('');
    const [task, setTask] = useState({
        title: '',
        description: '',
        time_block: 25,
        to_do_date: '',
        to_do_time: '',
        category: null,
        project: null,
        notification_status: 0,
        file_uploaded: null,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [projects, setProjects] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [existingFile, setExistingFile] = useState(null);
    if (task.file_uploaded) {
        setExistingFile(task.file_uploaded);
    }


    useEffect(() => {
        document.title = task.title ? `${task.title}` : 'New Task';
        setHeaderTitle(task.title ? `${task.title}` : 'New Task');
    }, [title, setHeaderTitle]);

    useEffect(() => {
        if (taskId) {
            setIsEditing(true);
            axios.get(`/api/tasks/${taskId}/`)
                .then(response => {
                    const taskData = response.data;
                    setTitle(taskData.title);
                    setTask({
                        title: taskData.title,
                        description: taskData.description,
                        time_block: taskData.time_block,
                        to_do_date: taskData.to_do_date ? taskData.to_do_date : '',
                        to_do_time: taskData.to_do_time ? taskData.to_do_time : '',
                        category: taskData.category ? taskData.category : '',
                        project: taskData.project ? taskData.project : '',
                        notification_status: taskData.notification_status,
                        file_uploaded: null, // Reset file upload for edit
                    });
                })
                .catch(error => {
                    console.error("There was an error fetching the task!", error);
                });
        }
        axios.get('/api/tasks/cats/')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the categories!", error);
            });
        axios.get('/api/tasks/pros/')
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the projects!", error);
            });
    }, [taskId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setTitle(taskData.title);
        setTask(prevState => ({
            ...prevState,
            [name]: value,
        }));
        if (name === 'title') {
            setTitle(value);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setTask(prevState => ({
            ...prevState,
            file_uploaded: file,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const taskData = new FormData();
            Object.entries(task).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    taskData.append(key, value);
                }
            });

            const request = taskId ? axios.patch : axios.post;
            const url = taskId ? `/api/tasks/${taskId}/` : '/api/tasks/new/';
            
            await request(url, taskData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            navigate(`/tasks/${taskId ? taskId : ''}/`);
        } catch (error) {
            setError(error.response.data);
            console.error("There was an error saving the task!", error);
        } finally {
            setLoading(false);
            navigate('/')
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`/api/tasks/${taskId}/`);
            navigate(returnUrl);
        } catch (error) {
            console.error("There was an error deleting the task!", error);
        }
    };

    return (
        <div className="main-content">
            <form onSubmit={handleSubmit}>
                <h2 className='info-color'>Required fields: </h2>
                {error && <div className="error-color">{JSON.stringify(error)}</div>}
                <div className="form-group">
                    <label htmlFor="task-title">Task Title <span className='error-color'>*</span></label>
                    <input 
                        type="text" 
                        id="task-title" 
                        name="title"
                        value={task.title} 
                        onChange={handleChange} 
                        placeholder="Title your task .." 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="time-block">Time Block <span className='error-color'>*</span></label>
                    <div className="time-block">
                        <button type="button" className="text-button huge-text-size primary-color-btn" onClick={() => setTask(prevState => ({...prevState, time_block: Math.max(prevState.time_block - 5, 5)}))}>-</button>
                        <input className="large-text-size" type="text" id="time-block" name="time_block" value={task.time_block} onChange={handleChange} required />
                        <button type="button" className="text-button huge-text-size primary-color-btn" onClick={() => setTask(prevState => ({...prevState, time_block: prevState.time_block + 5}))}>+</button>
                        <span>minutes</span>
                    </div>
                </div>
                <hr className='info-color' />
                <h2 className='info-color padding-vertical'>Optional: </h2>
                <div className="form-group">
                    <label htmlFor="task-desc">Describe what you should do...</label>
                    <Editor
                        apiKey='jrxykn4cnl0robolw7fgg3gilpks2s372q72xyb81rrw7hrr'
                        value={task.description}
                        init={{
                            license_key: 'gpl',
                            skin_url: '../../tinymce/skins/ui/oxide/',
                            content_css: '../../tinymce/skins/content/default/content.min.css',
                            height: 300,
                            menubar: true,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat',
                        }}
                        onEditorChange={(newContent) => setTask(prevState => ({...prevState, description: newContent}))}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="category">Category</label>
                    <select 
                        id="category" 
                        name="category"
                        className="dropdown" 
                        value={task.category}
                        onChange={handleChange}
                    >
                        <option value="">Select a category</option>
                        {categories.map(cat => (
                            <option key={cat.id} value={cat.id}>{cat.title}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="project">Project</label>
                    <select 
                        id="project" 
                        name="project"
                        className="dropdown" 
                        value={task.project}
                        onChange={handleChange}
                    >
                        <option value="">Select a project</option>
                        {projects.map(proj => (
                            <option key={proj.id} value={proj.id}>{proj.title}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="set-date">Set Date</label>
                    <input 
                        type="date" 
                        formTarget="yyyy-MM-DD"
                        id="set-date" 
                        name="to_do_date"
                        value={task.to_do_date} 
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="set-time">Set Time</label>
                    <input 
                        type="time" 
                        formTarget='HH:mm'
                        id="set-time" 
                        name="to_do_time"
                        value={task.to_do_time} 
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="notification">Notification</label>
                    <select 
                        id="notification" 
                        name="notification_status"
                        className="dropdown" 
                        value={task.notification_status} 
                        onChange={handleChange} 
                    >
                        <option value="0">None</option>
                        <option value="5">5 minutes before</option>
                        <option value="10">10 minutes before</option>
                        <option value="15">15 minutes before</option>
                        <option value="30">30 minutes before</option>
                        <option value="60">1 hour before</option>
                        <option value="240">4 hours before</option>
                        <option value="360">6 hours before</option>
                        <option value="1440">1 day before</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Related File 
                            {existingFile ? (<a 
                                className='text-button no-decoration active' 
                                href={existingFile} 
                                target="_blank" 
                                rel="noopener noreferrer">
                                    <span>Uploaded</span>
                            </a>) : ''}
                        </label>
                    <input type="file" name="file_uploaded" onChange={handleFileChange} />
                </div>
                <div className="buttons">
                    {isEditing && <button type="button" className="text-button error-color small-btn" onClick={handleDelete}>Delete</button>}
                    <button type="button" className="gray-btn" onClick={() => navigate(returnUrl)}>Cancel</button>
                    <button type="submit" className="primary-color-btn">{taskId ? 'Update' : 'Create'}</button>
                </div>
            </form>
        </div>
    );
}

export default TaskForm;
