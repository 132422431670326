import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import { useNavigate } from 'react-router-dom';
import axios from './axiosConfig';
import parse from 'html-react-parser'; // Import html-react-parser
// import { convertUTCToLocal } from './utils/timezone';
import "./AllItemsStyles.css";
import "./Tasks.css";


function Tasks() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null); // null for all (never use), 0 for Not Scheduled, 1 for Scheduled, 2 for Passed
    const navigate = useNavigate();

    const { setHeaderTitle } = useContext(HeaderContext);

    useEffect(() => {
        setHeaderTitle('List of Tasks');
    }, [setHeaderTitle]);

    const fetchTasks = (status) => {
        const params = {};
        if (status !== null) {
            params.task_status = status;
            // if (status === 2) {
            //     params.task_status__gt = 1; // Passed
            // } else {
            //     params.task_status = 0; // Not Scheduled
            // }
            setLoading(false);
        }
        axios.get('/api/tasks/', { params })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchTasks(selectedStatus);
    }, [selectedStatus]);

    const handleEdit = (taskId) => {
        navigate(`/tasks/${taskId}/`, { state: { returnUrl: '/tasks/' } });
    };

    const handleAdd = () => {
        navigate('/tasks/new/', { state: { returnUrl: '/tasks/' } });
    };

    const formatDate = (datetime) => {
        if (!datetime) {
            return 'No date';
        }
        return datetime
        // return convertUTCToLocal(datetime);
        // return new Date(datetime).toLocaleDateString();
    };


    const changeStatusFilter = (status) => {
        setSelectedStatus(status);
    };


    if (loading) {
        return <div className='popup-window message loading-box'>Loading...</div>;
    }

    
    if (error) {
        return <div className='popup-window message error-box'>Error: {error.message}</div>;
    }

    return (
        <div className="main-content">
            <div className="center-contents">
                <button 
                    className={`text-button no-decoration ${selectedStatus === 2 ? 'primary-color-btn' : 'active'}`} 
                    onClick={() => changeStatusFilter(2)}
                >
                    Passed
                </button>
                <button 
                    className={`text-button no-decoration ${selectedStatus === 1 ? 'primary-color-btn' : 'active'}`} 
                    onClick={() => changeStatusFilter(1)}
                >
                    TO DO
                </button>
                <button 
                    className={`text-button no-decoration ${selectedStatus === 0 ? 'primary-color-btn' : 'active'}`} 
                    onClick={() => changeStatusFilter(0)}
                >
                    Not Scheduled
                </button>                
            </div>
            {data.length === 0 ? (
                <div className="no-tasks center-contents">
                    <img src="../images/Ordopus_nothing_here.jpg" alt="No tasks" className="no-tasks-image" />
                    <p className="no-tasks-message center">There are no tasks to show.</p>
                </div>
            ) : (
                <>
                    <div className="task-list-header">
                        <div className="header-item todo-column">TO DO</div>
                        <div className="header-item date-column">DATE</div>
                        <div className="header-item todo-tbox-column">T-BOX</div>
                        <div className="header-item edit-column">EDIT</div>
                    </div>
                    {data.map((task, index) => (
                        <div key={task.id} className={`task-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                            <div className="todo-column">
                                <div className="description">{task.project ? task.project_title : 'No Project'}</div>
                                <div className="message">{task.title} {parse(task.description) && parse(task.description.substring(0, 150))}</div>
                            </div>
                            <div className="date-column">{formatDate(task.to_do_date)}</div>
                            <div className="todo-tbox-column">{task.time_block} minutes</div>
                            <div className="edit-column">
                            <img 
                                alt="edit icon" 
                                className="small-icon button-type" 
                                onMouseOver={(e) => e.currentTarget.src = "/images/icons/colored-edit.png"} 
                                onMouseLeave={(e) => e.currentTarget.src = "/images/icons/gray-edit.png"} 
                                src="/images/icons/gray-edit.png" 
                                onClick={() => handleEdit(task.id)} 
                            />
                            </div>
                        </div>
                    ))}
                </>
            )}
            <button type="button" className="full-button primary-color-btn weighted-btn" onClick={handleAdd}>
                <img alt="add task icon" src="/images/icons/white-task.png" className="icon" /> Add new task
            </button>
        </div>
    );
}

export default Tasks;
