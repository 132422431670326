import React, { useEffect, useState } from 'react';
import './DigitalTimer.css';

function DigitalTimer({ minutes, onTimerEnd, running, timeLeft, setTimeLeft }) {
    useEffect(() => {
        let timer;
        if (running) {
            timer = setInterval(() => {
                setTimeLeft(prevTimeLeft => {
                    if (prevTimeLeft > 0) {
                        return prevTimeLeft - 1;
                    } else {
                        clearInterval(timer);
                        onTimerEnd();
                        return 0;
                    }
                });
            }, 1000);
        } else {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [running, onTimerEnd, setTimeLeft]);

    const formatTime = () => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const getColor = () => {
        const percentageLeft = (timeLeft / (minutes * 60)) * 100;
        if (percentageLeft > 65) {
            return 'green';
        } else if (percentageLeft > 35) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    return (
        <div className="digital-timer" style={{ color: getColor() }}>
            {formatTime()}
        </div>
    );
}

export default DigitalTimer;
