import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';

const RegisterButton = () => {
    const navigate = useNavigate();

    const handleRegisterRequest = () => {
        navigate('/register/');
    };

    return (
        <div>
        <span>Don't have an account? </span>
        <a href="/register/" className="text-link secondary-color" onClick={handleRegisterRequest}>Sign Up</a>
</div>
    );
};

export default RegisterButton;