import React, { useState, useEffect } from 'react';
import BlockCloseButton from './BlockCloseButton';

import "./BlockSettings.css";

function BlockSettingTimeFormat({ setting, onClose, onSave }) {
//   localStorage.setItem('token', 'b982aff202e1b05b38390e8a0a2601a8bc596ead');

  const [selectedTimeFormat, setSelectedTimeFormat] = useState('%H:%M');

  useEffect(() => {
      if (setting) {
        setSelectedTimeFormat(setting);
      }
  }, [setting]);

  const handleSave = () => {
      onSave({ time_format: selectedTimeFormat });
  };


  return (
        <div className="outer-popup-window">
<div className="popup-window">
<BlockCloseButton onClose={onClose} />
<h2>Time Format</h2>
<ul className="items-list">
    <li>
    <input
          type="radio"
          id="twentyfour"
          name="timeformat"
          value="%H:%M"
          checked={selectedTimeFormat === '%H:%M'}
          onChange={(e) => setSelectedTimeFormat(e.target.value)}
      />
      <label htmlFor="twentyfour">24-Hour format (eg. 20:21)</label>
    </li>
    <li>
    <input
          type="radio"
          id="twelve"
          name="timeformat"
          value="%I:%M %p"
          checked={selectedTimeFormat === '%I:%M %p'}
          onChange={(e) => setSelectedTimeFormat(e.target.value)}
      />
      <label htmlFor="twelve">12-Hour format (eg. 08:21 PM)</label>
    </li>
</ul>
<div className="flex-container spacing">
    <button type="button" className="gray-btn" onClick={onClose}>Cancel</button>
    <button type="button" className="primary-color-btn" onClick={handleSave}>Save</button>
</div>
</div>
</div>
    );
}
export default BlockSettingTimeFormat;