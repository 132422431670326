import React, { useEffect, useState } from 'react';
import axios from './axiosConfig';
import BlockCloseButton from './BlockCloseButton';

import './BlockFeedback.css';

function BlockRateUs({ onClose}) {
    const [rateOrdi, setRateOrdi] = useState();

    const handleSave = () => {
            axios.post(`/api/analysis/rateus/`,{score: rateOrdi})
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error sending your score!", error);
                });
    };

    return (
        <div className="outer-popup-window">
            <div className="popup-window">
            <BlockCloseButton onClose={onClose} />
                <h2>Do you like Ordi?</h2>
                <div className="emoji-icons">
                    😃 😐 😟 
                </div>
                <div className="rating-stars">
                    <input type="radio" id="star5" name="rating" value="5" onChange={(e) => setRateOrdi(e.target.value)} /><label htmlFor="star5">★</label>
                    <input type="radio" id="star4" name="rating" value="4" onChange={(e) => setRateOrdi(e.target.value)} /><label htmlFor="star4">★</label>
                    <input type="radio" id="star3" name="rating" value="3" onChange={(e) => setRateOrdi(e.target.value)} /><label htmlFor="star3">★</label>
                    <input type="radio" id="star2" name="rating" value="2" onChange={(e) => setRateOrdi(e.target.value)} /><label htmlFor="star2">★</label>
                    <input type="radio" id="star1" name="rating" value="1" onChange={(e) => setRateOrdi(e.target.value)} /><label htmlFor="star1">★</label>
                </div>
                <button type="button" className="full-button primary-color-btn" onClick={handleSave}>Submit</button>
            </div>
        </div>
    );
}
export default BlockRateUs;
