// CustomModal.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CustomModal.css';


const CustomModal = ({onClose, show, title, message, className, redirectPath }) => {
    const navigate = useNavigate();

    const handleClose = () => {        
        if (redirectPath) {
            navigate(redirectPath);
        }
        onClose();
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay" onClick={handleClose}>
            <div className={`modal ${className}`}>
                <div className="modal-content">
                    <h2>{title}</h2>
                    <p>{message}</p>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
