import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import axios from './axiosConfig'; // Import the configured axios instance
import BlockAddCategory from './BlockAddCategory';
import "./AllItemsStyles.css"

function CategoryList() {
    // localStorage.setItem('token', 'b982aff202e1b05b38390e8a0a2601a8bc596ead');

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { setHeaderTitle } = useContext(HeaderContext);

    useEffect(() => {
        setHeaderTitle('Categories help you manage your life');
    }, [setHeaderTitle]);

    const fetchCategories = () => {
        axios.get('/api/tasks/cats/')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []); // Empty dependency array means this effect runs once after the initial render


    const handleEdit = (category) => {
        setSelectedCategory(category);
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedCategory(null);
        fetchCategories(); // Refresh the list after closing the popup
    };

    const formatDate = (datetime) => {
        return new Date(datetime).toLocaleDateString();
    };

    if (loading) {
        return <div className='popup-window message loading-box'>Loading...</div>;
    }

    if (error) {
        return <div className='popup-window message error-box'>Error: {error.message}</div>;
    }

    return (
    <div className="main-content">
        <div className="task-list-header">
            <div className="header-item any-item-column">CATEGORY TITLE</div>
            <div className="header-item edit-column">EDIT</div>
        </div>
        {data.map((category, index) => (
                <div key={category.id} className={`task-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                    <div className="any-item-column">
                        <div className="message">{category.title}</div>
                        <div className="description">{formatDate(category.created)}</div>
                    </div>
                    <div className="edit-column">
                        <img 
                        alt="edit icon" 
                        className="small-icon button-type" 
                        onMouseOver={(e) => e.currentTarget.src = "/images/icons/colored-edit.png"} 
                        onMouseLeave={(e) => e.currentTarget.src = "/images/icons/gray-edit.png"} 
                        src="/images/icons/gray-edit.png" 
                        onClick={() => handleEdit(category)} />
                    </div>
                </div>
            ))}
        <button type="button" className="full-button primary-color-btn weighted-btn" onClick={() => handleEdit()}>
        <img alt="category icon" src="/images/icons/white-categories.png" className="icon" /> Add new category</button>
        {isPopupOpen && <BlockAddCategory onClose={handleClosePopup} category={selectedCategory} />}
    </div>
);
}

export default CategoryList;