import React, { useState, useEffect } from 'react';
import axios from './axiosConfig'; // Import the configured axios instance
import BlockCloseButton from './BlockCloseButton';

function BlockAddCategory({ onClose, category }) {


    const [title, setTitle] = useState(category ? category.title : '');

    const handleSave = () => {
        if (category) {
            axios.put(`/api/tasks/cat/${category.id}/`, { title })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error updating the category!", error);
                });
        } else {
            axios.post('/api/tasks/cats/', { title })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error creating the category!", error);
                });
        }
    };

    const handleDelete = () => {
        if (category) {
            axios.delete(`/api/tasks/cat/${category.id}/`)
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error deleting the category!", error);
                });
        }
    };

    return (
        <div className="outer-popup-window">
        <div className="popup-window">
        <BlockCloseButton onClose={onClose} />
        <h2>{category ? 'Edit Category' : 'Add a Category'}</h2>
        <input
                    type="text"
                    placeholder="Enter Category title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
        />

        <div className="help-text">Categories may help you manage the balance of your workflow. For each category, you can add some projects.</div>
        <div className="flex-container spacing">
            {category && <button type="button" className="text-button delete-account small-btn" onClick={handleDelete}>Delete</button>}
            <button type="button" className="gray-btn small-btn" onClick={onClose}>Cancel</button>
            <button type="button" className="primary-color-btn small-btn" onClick={handleSave}>{category ? 'Save' : 'Create'}</button>
        </div>
        </div>
        </div>
    );
}
export default BlockAddCategory;