import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import axios from './axiosConfig'; // Import the configured axios instance
import BlockAddQuickNote from './BlockAddQuickNote';
import parse from 'html-react-parser'; // Import html-react-parser
import "./AllItemsStyles.css";

function QuickNotes() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedNote, setSelectedNote] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { setHeaderTitle } = useContext(HeaderContext);

    useEffect(() => {
        setHeaderTitle('Leave quick notes, free your mind');
    }, [setHeaderTitle]);

    const fetchQuickNotes = () => {
        axios.get('/api/tasks/qnotes/')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchQuickNotes();
    }, []); // Empty dependency array means this effect runs once after the initial render

    const handleEdit = (note) => {
        setSelectedNote(note);
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedNote(null);
        fetchQuickNotes(); // Refresh the list after closing the popup
    };

    const formatDate = (datetime) => {
        return new Date(datetime).toLocaleDateString();
    };

    if (loading) {
        return <div className='popup-window message loading-box'>Loading...</div>;
    }

    if (error) {
        return <div className='popup-window message error-box'>Error: {error.message}</div>;
    }

    return (
    <div className="main-content">
        
    <div className="task-list-header">
    <div className="header-item any-item-column">QUICK NOTE</div>
    <div className="header-item edit-column">EDIT</div>
    </div>
    {data.map((note, index) => (
                <div key={note.id} className={`task-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                    <div className="any-item-column">
                        <div className="description">{formatDate(note.created)}</div>
                        <div className="message">{parse(note.note.substring(0, 150))} ...</div>
                    </div>
                    <div className="edit-column">
                        <img 
                        alt="edit icon" 
                        className="small-icon button-type" 
                        onMouseOver={(e) => e.currentTarget.src = "/images/icons/colored-edit.png"} 
                        onMouseLeave={(e) => e.currentTarget.src = "/images/icons/gray-edit.png"} 
                        src="/images/icons/gray-edit.png"
                         onClick={() => handleEdit(note)} />
                    </div>
                </div>
    ))}
    <button type="button" className="full-button primary-color-btn weighted-btn" onClick={() => handleEdit(null)}>
    <img alt="add quick note icon" src="/images/icons/white-quick-notes.png" className="icon" /> Add a new quick note</button>
    {isPopupOpen && <BlockAddQuickNote onClose={handleClosePopup} note={selectedNote} />}
    </div>
);
}

export default QuickNotes;