import React, { useEffect, useState } from 'react';
import axios from './axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import BlockCloseButton from './BlockCloseButton';

import "./BlockSettings.css";

function BlockWantToDo({ onClose }) {
    // localStorage.setItem('token', 'b982aff202e1b05b38390e8a0a2601a8bc596ead');

    const { taskId } = useParams();
    const navigate = useNavigate();
    const [showFeedback, setShowFeedback] = useState(false);
    const [showCrashReason, setShowCrashReason] = useState(false);

    const handleFinish = () => {
        setShowFeedback(true);
    };

    const handleReSchedule = () => {
        console.log(`user got more time for task_id=${taskId}.`);
        axios.patch(`/api/tasks/${taskId}/`, { task_status: 2 })
            .then(() => navigate(`/tasks/${taskId}/`))
            .catch(error => console.error('Error updating task:', error));
    };

    const handleCrash = () => {
        setShowCrashReason(true);
    };

    return (
        <div className="outer-popup-window">
            <div className="popup-window">
            <BlockCloseButton onClose={onClose} />
                <h2>Time's up! What do you want to do?</h2>
                <ul className="items-list">
                    <li>
                        <button type="button" className="primary-color-btn full-button" onClick={handleFinish}>Finished</button>
                    </li>
                    <li>
                        <button type="button" className="warning-color-btn full-button" onClick={handleReSchedule}>More Time</button>
                    </li>
                    <li>
                        <button type="button" className="error-color-btn full-button" onClick={handleCrash}>Crash</button>
                    </li>
                </ul>
                <div className="flex-container spacing">
                    <button type="button" className="gray-btn" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}
export default BlockWantToDo;
