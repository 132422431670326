import React, { useState } from 'react';
import {Editor} from '@tinymce/tinymce-react';
import axios from './axiosConfig';
import BlockCloseButton from './BlockCloseButton';

function BlockAddQuickNote({ onClose, note }) {

    const [content, setContent] = useState(note ? note.note : '');

    const handleSave = () => {
        const noteData = { note: content };

        if (note) {
            axios.put(`/api/tasks/qnote/${note.id}/`, noteData)
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error updating the quick note!", error);
                });
        } else {
            axios.post('/api/tasks/qnotes/', noteData)
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error creating the quick note!", error);
                });
        }
    };

    const handleDelete = () => {
        if (note) {
            axios.delete(`/api/tasks/qnote/${note.id}/`)
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error deleting the quick note!", error);
                });
        }
    };



    return (
<div className="outer-popup-window">
<div className="popup-window">
<BlockCloseButton onClose={onClose} />
<h2>{note ? 'Edit Quick Note' : 'Add a Quick Note'}</h2>
<Editor
    // this api is created by my personalemail as CIH Brainware Family organization. Should be replace.
    apiKey='jrxykn4cnl0robolw7fgg3gilpks2s372q72xyb81rrw7hrr'
    value={content}
    init={{
        license_key: 'gpl',
        skin_url: '../tinymce/skins/ui/oxide/',
        content_css: '../tinymce/skins/content/default/content.min.css',
        height: 300,
        menubar: true,
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat',
    }}
    onEditorChange={(newContent) => setContent(newContent)}
/>
<p className="help-text">Quick notes take your worries out of your mind. You will have enough time to address them later. So, you don't need to think about them among your planned tasks.</p>
<div className="flex-container spacing">
    {note && <button type="button" className="text-button delete-account small-btn" onClick={handleDelete}>Delete</button>}
    <button type="button" className="gray-btn small-btn" onClick={onClose}>Cancel</button>
    <button type="button" className="primary-color-btn small-btn" onClick={handleSave}>{note ? 'Save' : 'Create'}</button>
</div>
</div>
</div>
    );
}
export default BlockAddQuickNote;