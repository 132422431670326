import React, { useState, useEffect } from 'react';
import BlockCloseButton from './BlockCloseButton';

import "./BlockSettings.css";

function BlockSelectLanguage({ setting, onClose, onSave }) {
  // localStorage.setItem('token', 'b982aff202e1b05b38390e8a0a2601a8bc596ead');

  const [selectedLanguage, setSelectedLanguage] = useState('en');

    useEffect(() => {
        if (setting) {
            setSelectedLanguage(setting.language);
        }
    }, [setting]);

    const handleSave = () => {
        onSave({ language: selectedLanguage });
    };

  return (
        <div className="outer-popup-window">
    <div className="popup-window">
    <BlockCloseButton onClose={onClose} />
    <h2>Language</h2>
  <ul className="items-list">
    <li>
    <input
          type="radio"
          id="english"
          name="language"
          value="en"
          checked={selectedLanguage === 'en'}
          onChange={(e) => setSelectedLanguage(e.target.value)}
      />
      <label htmlFor="english">English (US)</label>
    </li>
    <li>
    <input
          type="radio"
          id="persian"
          name="language"
          value="fa"
          checked={selectedLanguage === 'en'}
          onChange={(e) => setSelectedLanguage(e.target.value)}
      />
      <label htmlFor="persian">Persian (فارسی)</label>
    </li>
  </ul>
  <div className="flex-container spacing">
    <button type="button" className="gray-btn" onClick={onClose}>Cancel</button>
    <button type="button" className="primary-color-btn" onClick={handleSave}>Select</button>
</div>
</div>
  </div>
    );
}
export default BlockSelectLanguage;