import React, { useState, useEffect } from 'react';
import BlockCloseButton from './BlockCloseButton';

import "./BlockSettings.css";

function BlockSettingFirstDayWeek({ setting, onClose, onSave }) {
    const [selectedDay, setSelectedDay] = useState('saturday');

    useEffect(() => {
        if (setting) {
            setSelectedDay(setting);
        }
    }, [setting]);

    const handleSave = () => {
        onSave({ first_day: selectedDay });
    };

    return (
        <div className="outer-popup-window">
            <div className="popup-window">
            <BlockCloseButton onClose={onClose} />
                <h2>First Day of Your Weeks</h2>
                <ul className="items-list">
                    <li>
                        <input
                            type="radio"
                            id="saturday"
                            name="day"
                            value="saturday"
                            checked={selectedDay === 'saturday'}
                            onChange={(e) => setSelectedDay(e.target.value)}
                        />
                        <label htmlFor="saturday">Saturday</label>
                    </li>
                    <li>
                        <input
                            type="radio"
                            id="sunday"
                            name="day"
                            value="sunday"
                            checked={selectedDay === 'sunday'}
                            onChange={(e) => setSelectedDay(e.target.value)}
                        />
                        <label htmlFor="sunday">Sunday</label>
                    </li>
                    <li>
                        <input
                            type="radio"
                            id="monday"
                            name="day"
                            value="monday"
                            checked={selectedDay === 'monday'}
                            onChange={(e) => setSelectedDay(e.target.value)}
                        />
                        <label htmlFor="monday">Monday</label>
                    </li>
                </ul>
                <div className="flex-container spacing">
                    <button type="button" className="gray-btn" onClick={onClose}>Cancel</button>
                    <button type="button" className="primary-color-btn" onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default BlockSettingFirstDayWeek;
