// ForgotPassword.js
import React, { useState } from 'react';
import axios from './axiosConfig';
import CustomModal from './CustomModal';
import RegisterButton from './RegisterButton';
import "./Register.css";


function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        if (!validateEmail(emailValue)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Please enter a valid email address.'
            }));
        } else {
            setErrors((prevErrors) => {
                const { email, ...rest } = prevErrors;
                return rest;
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setErrors({ email: 'Please enter a valid email address.' });
            return;
        }

        try {
            await axios.post('/api/users/password-reset/', { email });
            setModalMessage('If this email is registered, you will receive a password reset link.');
            setShowModal(true);
        } 
        catch (error) {
            setModalMessage('An error occurred. Please try again later.');
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="register-container">
            <h2>Forgot Password</h2>
            {errors.email && <div className="error-color">{errors.email}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Enter your registered email"
                        value={email}
                        onChange={handleEmailChange}
                        className={errors.email ? 'input-error' : ''}
                    />
                </div>
                <button type="submit" className="action-button">Send Reset Link</button>
            </form>
            <RegisterButton />
            
            {showModal && (
                <CustomModal
                    show={showModal}
                    onClose={handleCloseModal}
                    message={modalMessage}
                    className="info-modal"
                />
            )}
        </div>
    );
}

export default ForgotPassword;
