import React, { useContext } from 'react';
import HeaderAvatar from './HeaderAvatar';
import MainMenu from './MainMenu';
import HeaderContext from './HeaderContext';
import './Header.css'


function Header(){
    const { headerTitle } = useContext(HeaderContext);
    return (
            <header className="header-conrainer">
                <div className="icon button-type">
                    <MainMenu />
                </div>

                <h1>{headerTitle}</h1>

                <HeaderAvatar />
            </header>
    )
};

export default Header;