import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import HeaderContext from './HeaderContext';
import CustomModal from './CustomModal';
import ForgotPasswordButton from './ForgotPasswordButton';
import AcceptOurRules from './AcceptOurRules';

import "./Login.css";

function Login() {
    const { setHeaderTitle } = useContext(HeaderContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const [error, setError] = useState('');
    const [showCustomModal, setShowCustomModal] = useState(false);
    // const navigate = useNavigate();

    useEffect(() => {
        setHeaderTitle('Welcome to Ordopus');
    }, [setHeaderTitle]);

    const handleLogin = async () => {
        if (!email || !password) {
            setError('Please enter both email and password.');
            console.log(error)
            return;
        }

        try {
            await login(email, password);
            handlePopupInfoClick();
            console.log('user logged in.')
        } catch (error) {
            setError('Invalid email or password.');
            console.log(error)
        }
    };

    const handlePopupInfoClick = () => {
        setShowCustomModal(true);
    };

    const handleCloseModal = () => {
        setShowCustomModal(false);
    };

    return (
        <div className='body'>
            <div className="login-container">
                <h2>Sign In</h2>
                {error && <div className="error-color">{error}</div>}
                <input 
                    type="email" 
                    placeholder="your.email@gmail.com"
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input 
                    type="password" 
                    placeholder="Password"
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="button" className="action-button" onClick={handleLogin}>SIGN IN</button>
                <ForgotPasswordButton />
                <div>
                    <span>Don't have an account? </span>
                    <a href="/register/" className="text-link secondary-color">Sign Up</a>
                </div>
                
                <AcceptOurRules />

            </div>
            {showCustomModal && <CustomModal
                show={showCustomModal}
                onClose={handleCloseModal}
                title="Hi!"
                message="Welcome back to Ordopus."
                className="success-modal"
                redirectPath="/"
            />}
        </div>
    );
}

export default Login;
