import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import { useNavigate } from 'react-router-dom';
import axios from './axiosConfig';
import BlockSelectLanguage from './BlockSelectLanguage';
import BlockSettingFirstDayWeek from './BlockSettingFirstDayWeek';
import BlockSettingTimeFormat from './BlockSettingTimeFormat';
import BlockRateUs from './BlockRateUs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import BlockInfo from './BlockInfo';
import "./SettingPart.css"

function SettingPart() {

    const [settings, setSettings] = useState(null);
    const [currentSetting, setCurrentSetting] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { setHeaderTitle } = useContext(HeaderContext);
    const [showRateUsPopup, setShowRateUsPopup] = useState(false);
    const [showBlockInfo, setShowBlockInfo] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        setHeaderTitle('Mark your preferred settings');
    }, [setHeaderTitle]);

    useEffect(() => {
        // Load settings from localStorage if available
        const storedSettings = localStorage.getItem('userSettings');
        if (storedSettings) {
            setSettings(JSON.parse(storedSettings));
            setLoading(false);
        } else {
            // Fetch settings from the server if not available in localStorage
            axios.get('/api/settings/')
                .then(response => {
                    const responseData = response.data[0]; // Assuming the first object is the relevant one
                    setSettings(responseData);
                    localStorage.setItem('userSettings', JSON.stringify(responseData)); // Save to localStorage
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, []);

    const handleIconClick = (settingType) => {
        setCurrentSetting(settingType);
        setShowPopup(true);
    };

    const handleShareIconClick = (item) => {
        if (item === 'invite-friends') {
            // Copy link to clipboard
            const link = inviteFriends;
            navigator.clipboard.writeText(link).then(() => {
                // Show info popup
                setShowBlockInfo(true);
                // Hide popup after 2 seconds
                setTimeout(() => setShowBlockInfo(false), 2000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        }
    };

    const handleRateIconClick = () => {
        setShowRateUsPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setShowRateUsPopup(false);
        setCurrentSetting(null);
    };

    const handleNavigation = (path, external = false) => {
        if (external) {
            window.open(path, '_blank');
        } else {
            navigate(path);
        }
        handleClosePopup();
    };

    const handleSaveSetting = (updatedSetting) => {
        // Update local memory
        const newSettings = { ...settings, ...updatedSetting };
        setSettings(newSettings);
        localStorage.setItem('userSettings', JSON.stringify(newSettings)); // Save to localStorage

        // Update database
        axios.put('/api/settings/edit/', updatedSetting)
            .then(response => {
                setShowPopup(false);
                setCurrentSetting(null);
            })
            .catch(error => {
                setError(error);
            });
    };
    const privacyPolicy = process.env.REACT_APP_PRIVACY_POLICY;    
    const supportCenter = process.env.REACT_APP_SUPPORT_CENTER;
    const inviteFriends = process.env.REACT_APP_INVITE_FRIENDS;


    if (loading) {
        return <div className="main-content">Loading...</div>;
    }

    if (error) {
        return <div className="main-content">Error: {error.message}</div>;
    }

    return (
        <div className="main-content">
            
            <ul className="menu">
                <li>
                <FormGroup>
                    <FormControlLabel label="Notifications" control={<Switch defaultChecked />}  />
                </FormGroup>
                </li>
                <li className='button-type' onClick={() => handleIconClick('language_selection')}
                    onMouseOver={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-language.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-language.png"}>
                    <img src="/images/icons/gray-language.png" alt="Language Icon" className="medium-icon" />
                    <span className="text">Language</span>
                    <span className="arrow">{'>'}</span>
                </li>
                <li onClick={() => handleIconClick('start_day_of_week')}
                    onMouseOver={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-week.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-week.png"} >
                    <img src="/images/icons/gray-week.png" alt="Week Icon" className="medium-icon" />
                    <span className="text">First Day of Week</span>
                    <span className="arrow">{'>'}</span>
                </li>
                <li onClick={() => handleIconClick('time_format')}
                    onMouseOver={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-time-format.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-time-format.png"} >
                    <img src="/images/icons/gray-time-format.png" alt="Time Icon" className="medium-icon" />
                    <span className="text">Time Format</span>
                    <span className="arrow">{'>'}</span>
                </li>
                <li onClick={() => handleIconClick('reminders')} 
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-reminder.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-reminder.png"}>
                    <img src="/images/icons/gray-reminder.png" alt="Projects Icon" className="medium-icon" />
                    <span className="text">Reminders</span>
                    <span className="arrow huge-text-size">{'>'}</span>
                </li>

                <hr className="light-gray-color" />

                <li onClick={() => handleNavigation(supportCenter, true)} 
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-customer-support.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-customer-support.png"}>
                    <img src="/images/icons/gray-customer-support.png" alt="Customer Support Icon" className="medium-icon" />
                    <span className="text">Support</span>
                    <span className="arrow huge-text-size">{'>'}</span>
                </li>
                <li onClick={() => handleRateIconClick('rate-us')}
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-rate-us.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-rate-us.png"}>
                    <img src="/images/icons/gray-rate-us.png" alt="Rate us Icon" className="medium-icon" />
                    <span className="text">Rate Ordi</span>
                    <span className="arrow huge-text-size">{'>'}</span>
                </li>
                <ul className="menu">
                <li onClick={() => handleShareIconClick('invite-friends')}
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-share.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-share.png"}>
                    <img src="/images/icons/gray-share.png" alt="Share Icon" className="medium-icon" />
                    <span className="text">Invite your friends</span>
                    <span className="arrow huge-text-size">{'>'}</span>
                </li>
            </ul>
                <li onClick={() => handleNavigation(privacyPolicy, true)} 
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-privacy-policy.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-privacy-policy.png"}>
                    <img src="/images/icons/gray-privacy-policy.png" alt="Privacy Policy Icon" className="medium-icon" />
                    <span className="text">Your privacy policy</span>
                    <span className="arrow huge-text-size">{'>'}</span>
                </li>
            </ul>
            {showPopup && currentSetting === 'start_day_of_week' && (
                <BlockSettingFirstDayWeek
                    setting={settings ? settings.first_day : 'saturday'}
                    onClose={handleClosePopup}
                    onSave={handleSaveSetting}
                />
            )}
            {showPopup && currentSetting === 'language_selection' && (
                <BlockSelectLanguage
                    setting={settings ? settings.language : 'en'}
                    onClose={handleClosePopup}
                    onSave={handleSaveSetting}
                />
            )}
            {showPopup && currentSetting === 'time_format' && (
                <BlockSettingTimeFormat
                    setting={settings ? settings.time_format : '%H:%M'}
                    onClose={handleClosePopup}
                    onSave={handleSaveSetting}
                />
            )}
            {showRateUsPopup && <BlockRateUs onClose={handleClosePopup} />}
            {showBlockInfo && <BlockInfo message="Link copied to clipboard!" />}
        </div>
    );
}

export default SettingPart;
