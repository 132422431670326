// PasswordResetConfirm.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from './axiosConfig';
import CustomModal from './CustomModal';
import "./Register.css";



function PasswordResetConfirm() {
    const { uidb64, token } = useParams();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showCustomModal, setShowCustomModal] = useState(false);

    const handlePasswordResetConfirm = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrors({ confirmPassword: ['Passwords do not match.'] });
            return;
        }
        try {
            await axios.post(`/api/users/password-reset-confirm/${uidb64}/${token}/`, {
                new_password1: newPassword,
                new_password2: confirmPassword,
            });
            setShowCustomModal(true);
        } catch (error) {
            setErrors(error.response.data);
        }
    };

    const handleCloseModal = () => {
        setShowCustomModal(false);
        navigate('/login/');
    };

    return (
        <div className='body'>
            <div className="reset-container">
                <h2>Reset Your Password</h2>
                {Object.keys(errors).length > 0 && (
                    <div className="error-color">
                        {Object.keys(errors).map((key) => (
                            errors[key].map((message, index) => (
                                <div key={`${key}-${index}`}>{message}</div>
                            ))
                        ))}
                    </div>
                )}
                <form onSubmit={handlePasswordResetConfirm}>
                    <div className="form-group">
                        <label>New Password</label>
                        <input
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Confirm New Password</label>
                        <input
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="action-button">Reset Password</button>
                </form>
            </div>
            {showCustomModal && (
                <CustomModal
                    show={showCustomModal}
                    onClose={handleCloseModal}
                    title="Password Reset!"
                    message="Your password has been successfully reset. Now, please login."
                    className="success-modal"
                />
            )}
        </div>
    );
}

export default PasswordResetConfirm;
