import React, { useState, useEffect } from 'react';
import axios from './axiosConfig'; // Import the configured axios instance
import BlockCloseButton from './BlockCloseButton';

function BlockAddProject({ onClose, project }) {

    const [title, setTitle] = useState(project ? project.title : '');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(project ? project.category : '');

    useEffect(() => {
        axios.get('/api/tasks/cats/')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the categories!", error);
            });
    }, []);

    const handleSave = () => {
        const projectData = {
            title,
            category: selectedCategory
        };
        
        if (project) {
            axios.put(`/api/tasks/pro/${project.id}/`, projectData)
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error updating the project!", error);
                });
        } else {
            axios.post('/api/tasks/pros/', projectData)
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error creating the project!", error);
                });
        }
    };

    const handleDelete = () => {
        if (project) {
            axios.delete(`/api/tasks/pro/${project.id}/`)
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error deleting the project!", error);
                });
        }
    };

    return (
        <div className="outer-popup-window">
            <div className="popup-window">
                <BlockCloseButton onClose={onClose} />
                <h2>{project ? 'Edit Project' : 'Add a Project'}</h2>
                <input
                    type="text"
                    placeholder="Enter project title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <select 
                    name="category" 
                    className="dropdown" 
                    value={selectedCategory} 
                    onChange={(e) => setSelectedCategory(e.target.value)}
                >
                    <option value="">Choose a Category</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.title}</option>
                    ))}
                </select>
                <div className="help-text">Each Project may have some tasks.</div>
                <div className="flex-container spacing">
                    {project && <button type="button" className="text-button delete-account small-btn" onClick={handleDelete}>Delete</button>}
                    <button type="button" className="gray-btn small-btn" onClick={onClose}>Cancel</button>
                    <button type="button" className="primary-color-btn small-btn" onClick={handleSave}>{project ? 'Save' : 'Create'}</button>
                </div>
            </div>
        </div>
    );
}

export default BlockAddProject;
