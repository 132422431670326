import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import { useNavigate } from 'react-router-dom';

import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay } from 'date-fns';
import axios from './axiosConfig';
import { useAuth } from './AuthContext';
import './Calendar.css';

const Calendar = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [taskDates, setTaskDates] = useState([]);
    const { setHeaderTitle } = useContext(HeaderContext);
    const { user, loading: userLoading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        setHeaderTitle('Review your performance');
    }, [setHeaderTitle]);

    useEffect(() => {
        if (user) {
            fetchTasksForMonth(currentMonth);
        }
    }, [currentMonth, user]);

    const fetchTasksForMonth = async (month) => {
        if (!user) return;
        setLoading(true);
        setError(null);
        try {
            axios.get('/api/tasks/monthly-tasks/', {
                params: { month: format(currentMonth, 'yyyy-MM') },
                headers: { Authorization: `Token ${localStorage.getItem('token')}` },
            }).then(response => {
                const tasks = response.data;
                // Use the tasks data...
            });
            setTasks(tasks);
            setTaskDates(tasks.map(task => task.date)); // Extract task dates for indicators
        } catch (err) {
            setError('Failed to fetch tasks');
        } finally {
            setLoading(false);
        }
    };

    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";

        return (
            <div className="calendar-header calendar-row flex-middle">
                <div className="calendar-col-header calendar-col-start center">
                    <div className="calendar-icon center" onClick={prevMonth}>
                        <img 
                            onMouseEnter={(e) => e.currentTarget.src = "/images/icons/colored-direction-back.png"} 
                            onMouseLeave={(e) => e.currentTarget.src = "/images/icons/black-direction-back.png"}
                            src="/images/icons/black-direction-back.png" alt="Back Icon" className="calendar-icon" />
                    </div>
                </div>
                <h2 className="calendar-col-header calendar-col-center">
                    <span>{format(currentMonth, dateFormat)}</span>
                </h2>
                <div className="calendar-col-header calendar-col-end" onClick={nextMonth}>
                    <img 
                        onMouseEnter={(e) => e.currentTarget.src = "/images/icons/colored-direction-forward.png"} 
                        onMouseLeave={(e) => e.currentTarget.src = "/images/icons/black-direction-forward.png"}
                        src="/images/icons/black-direction-forward.png" alt="Forward Icon" className="calendar-icon" />
                </div>
            </div>
        );
    };

    const renderDays = () => {
        const dateFormat = "iiii";
        const days = [];

        let startDate = startOfWeek(currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="calendar-col-name calendar-col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }

        return <div className="calendar-days calendar-row">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                const isTaskDay = taskDates.includes(format(day, 'yyyy-MM-dd'));

                days.push(
                    <div
                        className={`calendar-col calendar-cell ${
                            !isSameMonth(day, monthStart)
                                ? "calendar-disabled"
                                : isSameDay(day, selectedDate) ? "calendar-selected" : ""
                        }`}
                        key={day}
                        onClick={() => onDateClick(cloneDay)}
                    >
                        <span className="calendar-number">{formattedDate}</span>
                        {isTaskDay && <span className="task-indicator-dot"></span>}
                        <span className="calendar-bg">{formattedDate}</span>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="calendar-row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="calendar-body">{rows}</div>;
    };

    const renderTasks = () => {
        if (loading) {
            return <div className="calendar-tasks">Loading...</div>;
        }

        if (error) {
            return <div className="calendar-tasks">{error}</div>;
        }

        return (
            <div className="calendar-tasks">
                <h3>Tasks for {format(selectedDate, 'yyyy-MM-dd')}</h3>
                <ul className="calendar-task-list">
                    {tasks.filter(task => isSameDay(new Date(task.date), selectedDate)).map((task) => (
                        <li key={task.id} className="calendar-task-list-item">
                            {task.title}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const onDateClick = day => {
        setSelectedDate(day);
    };

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    return (
        <div className="calendar">
            {renderHeader()}
            {renderDays()}
            {renderCells()}
            {renderTasks()}
        </div>
    );
};

export default Calendar;








