// LogoutButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
// import './LogoutButton.css';

const LogoutButton = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login'); // Redirect to the login page after logout
    };

    return (
        <button type="button" className="text-button info-color"  onClick={handleLogout}>
            Logout
        </button>
    );
};

export default LogoutButton;
