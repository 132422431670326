import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import axios from './axiosConfig'; // Import your axios configuration
import { useParams, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import "./TaskDoing.css";
import DigitalTimer from './DigitalTimer';

function TaskDoing() {
    // localStorage.setItem('token', 'b982aff202e1b05b38390e8a0a2601a8bc596ead');

    const { taskId } = useParams();
    const navigate = useNavigate();
    const [task, setTask] = useState(null);
    const [timerRunning, setTimerRunning] = useState(false);
    const { setHeaderTitle } = useContext(HeaderContext);

    useEffect(() => {
        setHeaderTitle(task.title);
    }, [setHeaderTitle]);

    useEffect(() => {
        axios.get(`/api/tasks/${taskId}/`)
            .then(response => {
                setTask(response.data);
                setTimerRunning(true);
            })
            .catch(error => {
                console.error('Error fetching task:', error);
            });
    }, [taskId]);

    const handlePause = () => {
        setTimerRunning(false);
    };

    const handleFinish = () => {
        setTimerRunning(false);
        // Update task status to 'Done'
        axios.patch(`/api/tasks/${taskId}/`, { ...task, task_status: 3 })
            .then(() => navigate('/tasks/'))
            .catch(error => console.error('Error updating task:', error));
    };

    const handleMoreTime = () => {
        setTimerRunning(false);
        // Update task status to 'In Progress'
        axios.patch(`/api/tasks/${taskId}/`, { ...task, task_status: 2 })
            .then(() => navigate('/tasks'))
            .catch(error => console.error('Error updating task:', error));
    };

    const handleCrash = () => {
        setTimerRunning(false);
        // Update task status to 'Deleted'
        axios.patch(`/api/tasks/${taskId}/`, { ...task, task_status: -4 })
            .then(() => navigate('/tasks'))
            .catch(error => console.error('Error updating task:', error));
    };

    if (!task) {
        return <div className="error-box">Task not found</div>;
    }

    return (
        <div className="main-content">
            <div className="time-block-info">
                <h2>{task.title}</h2>
                <span className="time-block">{task.time_block} min</span>
                <img alt="music icon" src="../images/icons/icon-close.png" className="music icon large-icon button-type" />
            </div>
            <div className="task-card">
                {parse(task.description)}
            </div>
            <button type="button" className="button full-button error-color-btn" onClick={handleCrash}>CRASH</button>
            <div className="clock">
                <DigitalTimer minutes={task.time_block} onTimerEnd={handleFinish} />
            </div>
            
            <div className="buttons">
                <button type="button" className="pause warning-color-btn" onClick={handlePause}>Pause</button>
                <button type="button" className="finished success-color-btn" onClick={handleFinish}>Finished</button>
                <button type="button" className="more-time info-color-btn" onClick={handleMoreTime}>More time</button>
            </div>
        </div>
    );
}

export default TaskDoing;
