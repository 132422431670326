import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from './axiosConfig';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const tokenExpiry = localStorage.getItem('token_expiry');
        const userSettings = localStorage.getItem('userSettings');
        const storedUser = localStorage.getItem('user');

        if (token && new Date().getTime() < tokenExpiry) {
            if (storedUser) {
                setUser(JSON.parse(storedUser));
            } else {
                axios.get('/api/users/', {
                    headers: { Authorization: `Token ${token}` }
                }).then(response => {
                    setUser(response.data);
                    localStorage.setItem('user', JSON.stringify(response.data));
                    setLoading(false);
                }).catch(() => {
                    clearLocalStorage();
                    setLoading(false);
                });
            }
        } 
        else {
            clearLocalStorage();
            setLoading(false);
        }
    }, []);

    const clearLocalStorage = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiry');
        localStorage.removeItem('user');
        localStorage.removeItem('userSettings');
    };

    const login = async (email, password) => {
        try {
            clearLocalStorage();
            setUser(null);

            const response = await axios.post('/api/users/login/', { email, password });
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('token_expiry', new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
            localStorage.setItem('user', JSON.stringify(response.data.user)); // Store user data
            setUser(response.data.user);

        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const changePassword = async (passwordData) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post('/api/users/change-password/', passwordData, {
                headers: { Authorization: `Token ${token}` }
            });
        } catch (error) {
            console.error('Change password error:', error);
            throw error;
        }
    };

    const logout = () => {
        clearLocalStorage();
        setUser(null);
        navigate('/login/')
    };

    const register = async (userData) => {
        // Clear previous token
        clearLocalStorage();
        setUser(null);

        try {
            const response = await axios.post('/api/users/register/', userData);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user)); // Store user data
            localStorage.setItem('token_expiry', new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
            setUser(response.data.user);
        } catch (error) {
            console.error('Registration error:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, changePassword, logout, register, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
