import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';

const ForgotPasswordButton = () => {
    const navigate = useNavigate();

    const handleResetRequest = () => {
        navigate('/forgot-password/');
    };

    return (
            <div className='forgot-password-container'>
                    <a href="/forgot-password/" className="text-link" onClick={handleResetRequest}>Forgot password?</a>
            </div>
    );
};

export default ForgotPasswordButton;