import React from 'react';

function BlockCloseButtonWhite({ onClose }) {
    return (
        <div className="top-close-btn button-type" onClick={onClose}>
            <img 
                alt="close-icon" 
                className="icon" 
                onMouseOver={(e) => e.currentTarget.src = "/images/icons/white-close.png"} 
                onMouseLeave={(e) => e.currentTarget.src = "/images/icons/white-close.png"} 
                src="/images/icons/white-close.png"
            />
        </div>
    );
}

export default BlockCloseButtonWhite;
