import React, { useState, useEffect, useContext } from 'react';
import axios from './axiosConfig'; // Import the configured axios instance
import HeaderContext from './HeaderContext';
import LogoutButton from './LogoutButton';
import { useNavigate } from 'react-router-dom';
import './Profile.css';

function Profile() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showPopup, setShowPopup] = useState(null);
    const { setHeaderTitle } = useContext(HeaderContext);
    const navigate = useNavigate();


    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleNavigation = (path, external = false) => {
        if (external) {
            window.open(path, '_blank');
        } else {
            navigate(path);
        }
        handleClosePopup();
    };

    useEffect(() => {
        if (data && data.nick_name) {
            setHeaderTitle(`${data.nick_name}'s Profile`);
        } else {
            setHeaderTitle("Your Profile");
        }
    }, [data, setHeaderTitle]);


    useEffect(() => {
        let isMounted = true; // Track whether the component is mounted

        axios.get('api/users/profile/')
            .then(response => {
                if (isMounted) {
                    setData(response.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                if (isMounted) {
                    setError(error);
                    setLoading(false);
                }
            });

        return () => {
            isMounted = false; // Clean up function sets the flag to false
        };
    }, []); // Empty dependency array means this effect runs once after the initial render

    if (loading) {
        return <div className='popup-window message loading-box'>Loading...</div>;
    }

    if (error) {
        return <div className='popup-window message error-box'>Error: {error.message}</div>;
    }

    const deleteaccount = async () => {
        try {
            const response = await axios.post('api/users/delete-account/', {
                key: 'delete', // replace this with your data
            });
            setData(response.data);
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className='main-content'>
            <div className="profile-header">
            <div className="avatar"><img alt="user avatar" src={data.avatar} className="avatar large-avatar" /></div>
            <h1>{data.nick_name}</h1>
            <p className="username center">username: {data.username}</p>
            </div>
            <ul className="profilemenu">
                <li onClick={() => handleNavigation('/settings/')} 
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-settings.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-settings.png"}>
                    <img src="/images/icons/gray-settings.png" alt="Settings Icon" className="medium-icon" />
                    <span className="text">Settings</span>
                    <span class="arrow">{'>'}</span>
                </li>
                <li onClick={() => handleNavigation('/payments/')} 
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-payments.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-payments.png"}>
                    <img src="/images/icons/gray-payments.png" alt="Payments Icon" className="medium-icon" />
                    <span className="text">Payments</span>
                    <span class="arrow">{'>'}</span>
                </li>
                <li onClick={() => handleNavigation('/bonuses/')} 
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-bonus.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-bonus.png"}>
                    <img src="/images/icons/gray-bonus.png" alt="Bonus Icon" className="medium-icon" />
                    <span className="text">Your Bonus</span>
                    <span class="arrow">{'>'}</span>
                </li>
                <li className='menuItems' onClick={() => handleNavigation('/dashboard/')} 
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-dashboard.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-dashboard.png"}>
                    <img src="/images/icons/gray-dashboard.png" alt="Support Icon" className="medium-icon" />
                    <span className="text">Dashboard</span>
                    <span class="arrow">{'>'}</span>
                </li>
                <li onClick={() => handleNavigation('/changepassword/')}
                    onMouseEnter={(e) => e.currentTarget.firstChild.src = "/images/icons/colored-lock.png"} 
                    onMouseLeave={(e) => e.currentTarget.firstChild.src = "/images/icons/gray-lock.png"}>
                    <img src="/images/icons/gray-lock.png" alt="Settings Icon" className="medium-icon" />
                    <span className="text">Change Your Password</span>
                    <span class="arrow">{'>'}</span>
                </li>
            </ul>
            <div className="actions">
                <LogoutButton />
            {/* <button type="button" className="text-button error-color" onClick={deleteaccount}>Delete account</button> */}
            </div>
        </div>
);
}

export default Profile;