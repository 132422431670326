import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import PrivateRoute from './PrivateRoute';
import Header from './Header';
import { AuthProvider } from './AuthContext';
import FooterNavBar from './FooterNavBar';
import Dashboard from './Dashboard';
import Profile from './Profile';
import TaskForm from './TaskForm';
import SettingPart from './SettingPart';
import Tasks from './Tasks';
import FilteredTasks from './FilteredTasks';
import Calendar from './Calendar';
import TaskDoing from './TaskDoing';
import ProjectsList from './Projects';
import CategoryList from './Categories';
import QuickNotes from './QuickNotes';
import Login from './Login';
import Register from './Register';
import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import PasswordResetConfirm from './PasswordResetConfirm';
import TaskReadyToDo from './TaskReadyToDo';


function App() {
  const [popupType, setPopupType] = useState(null);

  const showPopup = (type) => {
      setPopupType(type);
  };

  const hidePopup = () => {
      setPopupType(null);
  };

  return (
    <>
    <div className="outer-container">
      <Router>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/dashboard/" exact element={<Dashboard />} />
            <Route path="/profile/" exact element={<Profile />} />
            <Route path="/settings/" exact element={<SettingPart onIconClick={showPopup}/>} />
            <Route path="/" exact element={<FilteredTasks/>} />
            <Route path="/tasks/" exact element={<Tasks />} />
            <Route path="/tasks/new/" exact element={<TaskForm />} />
            <Route path="/tasks/:taskId/" exact element={<TaskForm />} />
            <Route path="/calendar/" exact element={<Calendar />} />
            <Route path="/taskdoing/:taskId/" exact element={<TaskDoing />} />
            <Route path="/taskreadytodo/:taskId/" exact element={<TaskReadyToDo />} />
            <Route path="/categories/" exact element={<CategoryList />} />
            <Route path="/projects/" exact element={<ProjectsList />} />
            <Route path="/quicknotes/" exact element={<QuickNotes />} />
            <Route path="/login/" exact element={<Login />} />
            <Route path="/register/" exact element={<Register />} />
            <Route path="/changepassword/" exact element={<ChangePassword />} />
            <Route path="/forgot-password/" exact element={<ForgotPassword />} />
            <Route path="/reset-password/:uidb64/:token" exact element={<PasswordResetConfirm />} />
          </Routes>
          <FooterNavBar />
        </AuthProvider>
      </Router>
    </div>
    </>
  );
}

export default App;
