import React, { useEffect, useState } from 'react';
import axios from './axiosConfig';
import BlockCloseButton from './BlockCloseButton';
import CustomModal from './CustomModal';


import './BlockFeedback.css';

function BlockFeedback({ onClose, taskId }) {
    const [feedback, setFeedback] = useState();
    const [showCustomModal, setShowCustomModal] = useState(false);

    const handleSubmit = () => {
            axios.post(`/api/analysis/taskfeedback/`,{score: feedback, task_id: taskId})
                .then(response => {
                    handlePopupInfoClick();
                    onClose();
                })
                .catch(error => {
                    console.error("There was an error sending your score!", error);
                });
    };

    const handlePopupInfoClick = () => {
        setShowCustomModal(true);
    };

    const handleCloseModal = () => {
        setShowCustomModal(false);
    };


    return (
        <div className="outer-popup-window">
            <div className="popup-window">
            <BlockCloseButton onClose={onClose} />
                <h2>Score yourself</h2>
                <div className="emoji-icons">
                    😃 😐 😟 
                </div>
                <div className="rating-stars">
                    <input type="radio" id="star5" name="rating" value="5" /><label htmlFor="star5">★</label>
                    <input type="radio" id="star4" name="rating" value="4" /><label htmlFor="star4">★</label>
                    <input type="radio" id="star3" name="rating" value="3" /><label htmlFor="star3">★</label>
                    <input type="radio" id="star2" name="rating" value="2" /><label htmlFor="star2">★</label>
                    <input type="radio" id="star1" name="rating" value="1" /><label htmlFor="star1">★</label>
                </div>
                <button type="submit" className="full-button primary-color-btn" >Submit</button>
            </div>
            {showCustomModal && <CustomModal
                show={showCustomModal}
                onClose={handleCloseModal}
                title="Rate yourself"
                message="Your srore saved!"
                className="info-modal"
                redirectPath="/"
            />}
        </div>
    );
}
export default BlockFeedback;
