import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import axios from './axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import "./TaskDoing.css";
import DigitalTimer from './DigitalTimer';
import BlockFeedback from './BlockFeedback';
import BlockTaskCrashed from './BlockTaskCrashed';
import BlockWantToDo from './BlockWantToDo';

function TaskReadyToDo() {

    const { taskId } = useParams();
    const navigate = useNavigate();
    const [task, setTask] = useState(null);
    const [timerRunning, setTimerRunning] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isReadyToDo, setIsReadyToDo] = useState(true);
    const [showFeedback, setShowFeedback] = useState(false);
    const [showCrashReason, setShowCrashReason] = useState(false);
    const [showWantToDo, setShowWantToDo] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const { setHeaderTitle } = useContext(HeaderContext);


    useEffect(() => {
        axios.get(`/api/tasks/${taskId}/`)
            .then(response => {
                setTask(response.data);
                setTimeLeft(response.data.time_block * 60); // Set initial time left in seconds
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching task:', error);
                setError(error);
                setLoading(false);
            });
    }, [taskId]);

    useEffect(() => {
        if (task && task.title) {
        setHeaderTitle(task.title);
        }
    }, [setHeaderTitle]);

    const handleStartPause = () => {
        if (timerRunning) {
            setTimerRunning(false);
        } else {
            setIsReadyToDo(false);
            setTimerRunning(true);
        }
    };

    const handleFinish = () => {
        setTimerRunning(false);
        setShowFeedback(true);
    };


    const handleCrash = () => {
        setTimerRunning(false);
        setShowCrashReason(true);
    };

    const handleBackHome = () => {
        navigate('/');
    };

    const handleTimerEnd = () => {
        setTimerRunning(false);
        setShowWantToDo(true);
    };

    const handleClosePopup = () => {
        setShowFeedback(false);
        setShowCrashReason(false);
        setShowWantToDo(false);
        setTimerRunning(true);
    };

    if (loading) {
        return <div className="loading-box">Loading...</div>;
    }

    if (error) {
        return <div className="error-box">Error: {error.message}</div>;
    }

    return (
        <div className="main-content">
            {task ? (
                <>
                    <div className="time-block-info">
                        <h2>{task.title}</h2>
                        <span className="time-block">{task.time_block} min</span>
                        <img alt="music icon" src="../images/icons/colored-music-icon.png" className="music icon large-icon button-type" />
                    </div>
                    <div className="task-card">
                        {parse(task.description)}
                    </div>
                    {isReadyToDo ? (
                        <>
                            <button type="button" className="button full-button primary-color-btn weighted-btn large-text-size" onClick={handleStartPause}>START</button>
                            <button type="button" className="text-button full-button large-text-size" onClick={handleBackHome}>Back to Home</button>
                        </>
                    ) : (
                        <>
                            {!showFeedback && !showCrashReason && !showWantToDo && (
                                <div className="clock">
                                    <DigitalTimer 
                                        minutes={task.time_block} 
                                        onTimerEnd={handleTimerEnd} 
                                        running={timerRunning} 
                                        timeLeft={timeLeft} 
                                        setTimeLeft={setTimeLeft} 
                                    />
                                </div>
                            )}
                            <div className={`buttons ${showFeedback || showCrashReason || showWantToDo ? 'disabled' : ''}`}>
                                <button type="button" className="more-time error-color-btn" onClick={handleCrash} disabled={showFeedback || showCrashReason || showWantToDo}>Crash!</button>
                                <button type="button" className={`pause ${timerRunning ? 'warning-color-btn' : 'primary-color-btn'}`} 
                                onClick={handleStartPause} disabled={showFeedback || showCrashReason || showWantToDo}>
                                    {timerRunning ? 'Pause' : 'Start'}
                                </button>
                                <button type="button" className="finished success-color-btn" onClick={handleFinish} disabled={showFeedback || showCrashReason || showWantToDo}>Finished</button>
                            </div>
                        </>
                    )}
                    {showFeedback && <BlockFeedback onClose={handleClosePopup} />}
                    {showCrashReason && <BlockTaskCrashed onClose={handleClosePopup} />}
                    {showWantToDo && <BlockWantToDo onClose={handleClosePopup} />}
                </>
            ) : (
                <div className="error-box">Task not found</div>
            )}
        </div>
    );
}

export default TaskReadyToDo;
