import React from 'react';

function BlockCloseButton({ onClose }) {
    return (
        <div className="top-close-btn button-type" onClick={onClose}>
            <img 
                alt="close-icon" 
                className="icon" 
                onMouseOver={(e) => e.currentTarget.src = "/images/icons/colored-close.png"} 
                onMouseLeave={(e) => e.currentTarget.src = "/images/icons/gray-close.png"} 
                src="/images/icons/gray-close.png" 
            />
        </div>
    );
}

export default BlockCloseButton;
