import axios from 'axios';

// const URL = require(process.env.REACT_APP_API_URL).Url;
const apiUrl = process.env.REACT_APP_API_URL;

const instance = axios.create({
    baseURL: apiUrl,
    timeout: 2000,
    headers: {'Content-Type': 'application/json'}
});

instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Token ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default instance;
