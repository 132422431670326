import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import HeaderContext from './HeaderContext';
import CustomModal from './CustomModal';
import "./Register.css";

function ChangePassword() {
    const { setHeaderTitle } = useContext(HeaderContext);
    const { user, changePassword } = useContext(AuthContext);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showCustomModal, setShowCustomModal] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setHeaderTitle('Change Password');
    }, [setHeaderTitle]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setErrors({ confirmNewPassword: ['New passwords do not match.'] });
            return;
        }
        try {
            await changePassword({
                old_password: oldPassword,
                new_password1: newPassword,
                new_password2: confirmNewPassword,
            });
            handlePopupInfoClick();
            // navigate('/'); // Redirect to homepage after successful password change
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            } else {
                setErrors({ non_field_errors: ['An error occurred. Please try again.'] });
            }
        }
    };

    const handlePopupInfoClick = () => {
        setShowCustomModal(true);
    };

    const handleCloseModal = () => {
        setShowCustomModal(false);
    };


    return (
        <div className='body'>
            <div className="register-container">
                {Object.keys(errors).length > 0 && (
                    <div className="error-color">
                    {Object.keys(errors).map((key) => (
                        Array.isArray(errors[key]) ? (
                            errors[key].map((message, index) => (
                                <div key={`${key}-${index}`}>{message}</div>
                            ))
                        ) : (
                            <div key={key}>{errors[key]}</div>
                        )
                    ))}
                </div>
                )}
                <form className='' onSubmit={handleSubmit}>
                    <h3>All fields are required.</h3>
                <div className="form-group">
                        <label>Old Password <span className="error-color">*</span></label>
                        <input
                            type="password"
                            placeholder="Old Password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        {errors.old_password && <div className="error-color">{errors.old_password[0]}</div>}
                    </div>
                    <div className="form-group">
                        <label>New Password <span className="error-color">*</span></label>
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        {errors.new_password && errors.new_password.map((error, index) => (
                            <div key={index} className="error-color">{error}</div>
                        ))}
                    </div>
                    <div className="form-group">
                        <label>Confirm New Password <span className="error-color">*</span></label>
                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                        {errors.confirmNewPassword && <div className="error-color">
                            {errors.confirmNewPassword[0]}</div>}
                    </div>
                    <button type="submit" className="action-button">Change Password</button>
                </form>
                <div className='padding-vertical'>
                    <span className='help-text'>Already have your password? </span>
                    <a href="/login/" className="text-link">Sign In</a>
                </div>
            </div>
            {showCustomModal && <CustomModal
                show={showCustomModal}
                onClose={handleCloseModal}
                title="Done!"
                message="Password changed."
                className="success-modal"
                redirectPath="/profile/"
            />}
        </div>
    );
}

export default ChangePassword;
