import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import axios from './axiosConfig'; // Import the configured axios instance
import BlockAddProject from './BlockAddProject';
import "./AllItemsStyles.css";

function ProjectsList() {
    // localStorage.setItem('token', 'b982aff202e1b05b38390e8a0a2601a8bc596ead');
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { setHeaderTitle } = useContext(HeaderContext);

    useEffect(() => {
        setHeaderTitle('Life is a miracle project.');
    }, [setHeaderTitle]);

    const fetchProjects = () => {
        axios.get('/api/tasks/pros/')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchProjects();
    }, []); // Empty dependency array means this effect runs once after the initial render

    const handleEdit = (project) => {
        setSelectedProject(project);
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedProject(null);
        fetchProjects(); // Refresh the list after closing the popup
    };

    const formatDate = (datetime) => {
        return new Date(datetime).toLocaleDateString();
    };

    if (loading) {
        return <div className='popup-window message loading-box'>Loading...</div>;
    }

    if (error) {
        return <div className='popup-window message error-box'>Error: {error.message}</div>;
    }

    return (
        <div className="main-content">
            <div className="task-list-header">
                <div className="header-item any-item-column">PROJECT TITLE</div>
                <div className="header-item edit-column">EDIT</div>
            </div>
            {data.map((project, index) => (
                <div key={project.id} className={`task-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                    <div className="any-item-column">
                        <div className="message">{project.title}</div>
                        <div className="description">{formatDate(project.created)}</div>
                    </div>
                    <div className="edit-column">
                        <img 
                        alt="edit icon" 
                        className="small-icon button-type" 
                        onMouseOver={(e) => e.currentTarget.src = "/images/icons/colored-edit.png"} 
                        onMouseLeave={(e) => e.currentTarget.src = "/images/icons/gray-edit.png"} 
                        src="/images/icons/gray-edit.png" 
                        onClick={() => handleEdit(project)} />
                    </div>
                </div>
            ))}
            <button type="button" className="full-button primary-color-btn weighted-btn" onClick={() => handleEdit(null)}>
            <img alt="add project icon" src="/images/icons/white-projects.png" className="icon" /> Add new project</button>
            {isPopupOpen && <BlockAddProject onClose={handleClosePopup} project={selectedProject} />}
        </div>
    );
}

export default ProjectsList;
