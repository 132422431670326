import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import HeaderContext from './HeaderContext';
import AcceptOurRules from './AcceptOurRules';
import "./Register.css";
import "./App.css";

function Register() {
    const { setHeaderTitle } = useContext(HeaderContext);
    const { register } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nickName, setNickName] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    

    useEffect(() => {
        setHeaderTitle('Registration');
    }, [setHeaderTitle]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrors({ confirmPassword: ['Passwords do not match.'] });
            return;
        }
        try {
            await register({
                username,
                email,
                password,
                nick_name: nickName
            });
            navigate('/');
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            } else {
                setErrors({ non_field_errors: ['An error occurred. Please try again.'] });
            }
        }
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;

        setEmail(e.target.value);
        setUsername(e.target.value);

        const validateEmail = (email) => {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        };

        if (!validateEmail(emailValue)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: ['Please enter a valid email address.']
            }));
        } else {
            setErrors((prevErrors) => {
                const { email, ...rest } = prevErrors;
                return rest;
            });
        }
    };

    return (
        <div className='body'>
            <div className="register-container">
                {Object.keys(errors).length > 0 && (
                    <div className="error-color left">
                        {Object.keys(errors).map((key) => (
                            errors[key].map((message, index) => (
                                <div key={`${key}-${index}`}>{message}</div>
                            ))
                        ))}
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Input your email. Ordi uses it as your username, too.</label>
                        <input
                            type="email"
                            placeholder="youremail@example.com"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        {errors.email && <div className="error-color">{errors.email[0]}</div>}
                    </div>
                    <div className="form-group">
                        <label>Take a new password</label>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {errors.password && errors.password.map((error, index) => (
                            <div key={index} className="error-color">{error}</div>
                        ))}
                    </div>
                    <div className="form-group">
                        <label>Repeat the password</label>
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {errors.confirmPassword && <div className="error-color">
                            {errors.confirmPassword[0]}</div>}
                    </div>
                    <div className="form-group">
                        <label>Take a nick name, if you are interested.</label>
                        <input
                            type="text"
                            placeholder="Nick Name"
                            value={nickName}
                            onChange={(e) => setNickName(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="action-button">Register</button>
                </form>
                <div>
                    <span className='help'>Already have an account? </span>
                    <a href="/login/" className="text-link secondary-color">Sign In</a>
                </div>
                <AcceptOurRules />
            </div>
        </div>
    );
}

export default Register;
