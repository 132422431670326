// HeaderAvatar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './Header.css';

function HeaderAvatar() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const handleAvatarClick = () => {
        if (user) {
            navigate('/profile/');
        } else {
            navigate('/login/');
        }
    };

    return (
        <div className="profile-icon" onClick={handleAvatarClick}>
            <img
                alt="user avatar"
                src={user?.avatar ? user.avatar : "/images/avatar.png"}
                className="profile-icon"
            />
        </div>
    );
}

export default HeaderAvatar;


