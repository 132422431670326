import React from 'react';
import BlockCloseButton from './BlockCloseButton';

import "./BlockSettings.css";

function BlockTaskCrashed({ onClose }) {
//   localStorage.setItem('token', 'b982aff202e1b05b38390e8a0a2601a8bc596ead');

    return (      
        <div className="outer-popup-window">
            <div className="popup-window">
            <BlockCloseButton onClose={onClose} />
                <h2>What's happened?</h2>
                <ul className="items-list">
                    <li>
                        <input type="radio" id="emergency" name="reason" value="emergency" defaultChecked />
                        <label htmlFor="emergency">Emergency event</label>
                    </li>
                    <li>
                        <input type="radio" id="not-motivated" name="reason" value="not-motivated" />
                        <label htmlFor="not-motivated">Not motivated!</label>
                    </li>
                    <li>
                        <input type="radio" id="not-necessary" name="reason" value="not-necessary" />
                        <label htmlFor="not-necessary">Not necessary</label>
                    </li>
                    <li>
                        <input type="radio" id="mistake" name="reason" value="mistake" />
                        <label htmlFor="mistake">I made a mistake!</label>
                    </li>
                    <li>
                        <input type="radio" id="cancelled" name="reason" value="cancelled" />
                        <label htmlFor="cancelled">Task is cancelled, forever!</label>
                    </li>
                </ul>
                <div className="flex-container spacing">
                    <button type="button" className="gray-btn" onClick={onClose}>Cancel</button>
                    <button type="button" className="primary-color-btn" onClick={onClose}>Save</button>
                </div>
            </div>
        </div>
    );
}
export default BlockTaskCrashed;
