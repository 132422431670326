import React, { useState, useEffect, useContext } from 'react';
import HeaderContext from './HeaderContext';
import { useNavigate } from 'react-router-dom';
import axios from './axiosConfig';
import parse from 'html-react-parser';
import { convertUTCToLocal } from './utils/timezone';
import "./AllItemsStyles.css";
import "./Tasks.css";

function FilteredTasks() {
    // localStorage.setItem('token', 'b982aff202e1b05b38390e8a0a2601a8bc596ead');

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterDate, setFilterDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedFilter, setSelectedFilter] = useState(0); // 0 for Today, -1 for Yesterday, 1 for Tomorrow
    const navigate = useNavigate();
    const { setHeaderTitle } = useContext(HeaderContext);

    useEffect(() => {
        setHeaderTitle('Tasks around you these days');
    }, [setHeaderTitle]);

    const fetchTasks = (date) => {
        axios.get('/api/tasks/filtered-tasks/', {
            params: { filter_date: date }
        })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchTasks(filterDate);
    }, [filterDate]);

    const handleEdit = (taskId) => {
        navigate(`/tasks/${taskId}/`, { state: { returnUrl: '/' } });
    };

    const handleStart = (taskId) => {
        navigate(`/taskreadytodo/${taskId}/`, { state: { returnUrl: '/' } });
    };

    const handleAdd = () => {
        navigate('/tasks/new/', { state: { returnUrl: '/' } });
    };

    const formatDate = (datetime) => {
        if (!datetime) {
            return 'None';
        }
        return convertUTCToLocal(datetime);
    };

    const changeDateFilter = (days) => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + days);
        setFilterDate(newDate.toISOString().split('T')[0]);
        setSelectedFilter(days);
    };

    if (loading) {
        return <div className='popup-window message loading-box'>Loading...</div>;
    }

    if (error) {
        return <div className='popup-window message error-box'>Error: {error.message}</div>;
    }

    return (
        <div className="main-content">
            <div className="center-contents">
            <button 
                    className={`text-button no-decoration ${selectedFilter === -1 ? 'primary-color-btn' : 'active'}`} 
                    onClick={() => changeDateFilter(-1)}
                >
                    Yesterday
                </button>
                <button 
                    className={`text-button no-decoration ${selectedFilter === 0 ? 'primary-color-btn' : 'active'}`} 
                    onClick={() => changeDateFilter(0)}
                >
                    Today
                </button>
                <button 
                    className={`text-button no-decoration ${selectedFilter === 1 ? 'primary-color-btn' : 'active'}`} 
                    onClick={() => changeDateFilter(1)}
                >
                    Tomorrow
                </button>
            </div>
            {data.length === 0 ? (
                <div className="no-tasks center-contents">
                    <img src="/images/Ordopus_nothing_here.jpg" alt="No tasks" className="no-tasks-image" />
                    <p className="no-tasks-message center">There are no tasks to show.</p>
                </div>
            ) : (
                <>
            <div className="task-list-header">
                <div className="header-item todo-column">TO DO</div>
                <div className="header-item date-column">TIME</div>
                <div className="header-item todo-tbox-column">T-BOX</div>
                <div className="header-item edit-column">EDIT</div>
            </div>
            {data.map((task, index) => (
                <div key={task.id} className={`task-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                    <div className="todo-column">
                        <div className="description button-type" onClick={() => handleStart(task.id)}>{task.project ? task.project_title : 'No Project'}</div>
                        <div className="message button-type" onClick={() => handleStart(task.id)}>{task.title} {parse(task.description) && parse(task.description.substring(0, 150))}</div>
                    </div>
                    <div className="date-column">{task.to_do_time}</div>
                    <div className="todo-tbox-column">{task.time_block} minutes</div>
                    <div className="edit-column" onClick={() => handleEdit(task.id)}>
                    <img 
                                alt="edit icon" 
                                className="small-icon button-type" 
                                onMouseOver={(e) => e.currentTarget.src = "/images/icons/colored-edit.png"} 
                                onMouseLeave={(e) => e.currentTarget.src = "/images/icons/gray-edit.png"} 
                                src="../images/icons/gray-edit.png" 
                                onClick={() => handleEdit(task.id)} 
                            />
                    </div>
                </div>
            ))}
            </>
        )}
            <button type="button" className="full-button primary-color-btn weighted-btn" onClick={handleAdd}>
                <img alt="add task icon" src="/images/icons/white-task.png" className="icon" /> Add new task
            </button>
        </div>
    );
}

export default FilteredTasks;
