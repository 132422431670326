import BlockCloseButtonWhite from './BlockCloseButtonWhite';
import React from 'react';

function BlockInfo({ onClose, message }) {
    return (        
        <div className="outer-popup-window">
            <div className="popup-window info-box">
                <BlockCloseButtonWhite onClose={onClose} />
                <p>{message}</p>
            </div>
        </div>
    );
}

export default BlockInfo;
