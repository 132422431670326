import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MainMenu.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

function MainMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigation = (path, external = false) => {
        if (external) {
            window.open(path, '_blank');
        } else {
            navigate(path);
        }
        handleClose();
    };

    return (
        <Fragment>
            <div
                onClick={handleClick}
                aria-controls={open ? 'side-main-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <img
                    alt="main-menu icon"
                    className="icon"
                    onMouseEnter={(e) => (e.currentTarget.src = '/images/icons/colored-main-menu.png')}
                    onClick={(e) => (e.src = '/images/icons/colored-main-menu.png')}
                    onMouseLeave={(e) => (e.currentTarget.src = '/images/icons/black-main-menu.png')}
                    src="/images/icons/black-main-menu.png"
                />
            </div>
            <Menu
                className="mainmenu"
                anchorEl={anchorEl}
                id="side-main-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                elevation={0}
                sx={{
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'white',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={() => handleNavigation('/categories/')}
                    onMouseEnter={(e) => (e.currentTarget.firstChild.src = '/images/icons/colored-categories.png')}
                    onMouseLeave={(e) => (e.currentTarget.firstChild.src = '/images/icons/gray-categories.png')}
                    className="mainmenuItem">
                    <img src="/images/icons/gray-categories.png" alt="Categories Icon" className="icon" />
                    <span className="text">Categories</span>
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigation('/projects/')}
                    onMouseEnter={(e) => (e.currentTarget.firstChild.src = '/images/icons/colored-projects.png')}
                    onMouseLeave={(e) => (e.currentTarget.firstChild.src = '/images/icons/gray-projects.png')}
                    className="mainmenuItem"
                >
                    <img src="/images/icons/gray-projects.png" alt="Projects Icon" className="icon" />
                    <span className="text">Projects</span>
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigation('/tasks/')}
                    onMouseEnter={(e) => (e.currentTarget.firstChild.src = '/images/icons/colored-tasks-list.png')}
                    onMouseLeave={(e) => (e.currentTarget.firstChild.src = '/images/icons/gray-tasks-list.png')}
                    className="mainmenuItem"
                >
                    <img src="/images/icons/gray-tasks-list.png" alt="Tasks Icon" className="icon" />
                    <span className="text">Tasks</span>
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigation('/quicknotes/')}
                    onMouseEnter={(e) => (e.currentTarget.firstChild.src = '/images/icons/colored-quick-notes.png')}
                    onMouseLeave={(e) => (e.currentTarget.firstChild.src = '/images/icons/gray-quick-notes.png')}
                    className="mainmenuItem"
                >
                    <img src="/images/icons/gray-quick-notes.png" alt="Quick Notes Icon" className="icon" />
                    <span className="text">Quick Notes</span>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => handleNavigation('/bonuses/')}
                    onMouseEnter={(e) => (e.currentTarget.firstChild.src = '/images/icons/colored-bonus.png')}
                    onMouseLeave={(e) => (e.currentTarget.firstChild.src = '/images/icons/gray-bonus.png')}
                    className="mainmenuItem"
                >
                    <img src="/images/icons/gray-bonus.png" alt="Bonus Icon" className="icon" />
                    <span className="text">Your Bonus</span>
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigation('/payments/')}
                    onMouseEnter={(e) => (e.currentTarget.firstChild.src = '/images/icons/colored-payments.png')}
                    onMouseLeave={(e) => (e.currentTarget.firstChild.src = '/images/icons/gray-payments.png')}
                    className="mainmenuItem"
                >
                    <img src="/images/icons/gray-payments.png" alt="Payments Icon" className="icon" />
                    <span className="text">Payments</span>
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigation('/settings/')}
                    onMouseEnter={(e) => (e.currentTarget.firstChild.src = '/images/icons/colored-settings.png')}
                    onMouseLeave={(e) => (e.currentTarget.firstChild.src = '/images/icons/gray-settings.png')}
                    className="mainmenuItem"
                >
                    <img src="/images/icons/gray-settings.png" alt="Settings Icon" className="icon" />
                    <span className="text">Settings</span>
                </MenuItem>
            </Menu>
        </Fragment>
    );
}

export default MainMenu;
